import React from "react";
import { Button, Empty, Typography, message } from "antd";
import { isValidElement } from "../../util/ApiHelper";
import { isValidString } from "util/ApiHelper";

const ListEmptyComponent = ({
  message = "No data available",
  buttonText = "Create new",
  onButtonClick,
}) => (
  <Empty
    image="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1280px-HD_transparent_picture.png"
    imageStyle={{ height: 60 }}
    description={<Typography.Text>{message}</Typography.Text>}
  >
    {isValidElement(onButtonClick) && isValidString(buttonText) && (
      <Button type="primary" onClick={() => onButtonClick()}>
        {buttonText}
      </Button>
    )}
  </Empty>
);

export default React.memo(ListEmptyComponent);
