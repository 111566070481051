import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Table } from "antd";
import { Dropdown } from "antd";
import { EditOutlined, LeftOutlined, MoreOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { showAlert } from "../../util/helper";
import { isArrayNonEmpty, isValidString } from "../../util/ApiHelper";
import { MY_EXIM_API_RFQ } from "../../appRedux/services/requestQuotationsNetwork";
import { useDispatch, useSelector } from "react-redux";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { RFQStatus } from "../../util/Constant";
import RFQStatusTags from "./RFQStatus";
import { formatDate } from "util/helper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { ItemDetailMobile } from "../../components/ItemDetailMobile";
import { safeStringValue } from "util/ApiHelper";
import { BUSINESS_TYPE } from "../../util/Constant";

const getPricePerUnit = (record) => {
  return `${safeStringValue(record?.currencyCode)} ${safeStringValue(
    record?.price
  )} / ${safeStringValue(
    record?.enquiries?.units,
    record?.enquiries?.shipmentMode === "sea"
      ? record?.enquiries?.shipmentType === "fcl"
        ? `Unit (${record?.enquiries?.containerType})`
        : "Cubic Meter"
      : "Cubic Meter"
  )}`;
};

const QuotationsList = () => {
  const [quotationList, SetQuotationList] = useState(null);
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading } = useSelector(({ common }) => common);
  const location = useLocation();
  const party = location?.state?.party;

  console.log('@@@ loading', loading);

  const navigateToDetails = useCallback(
    (id) => {
      history.push(`/rfq/quotations-details/${id}`);
    },
    [history]
  );

  const countries = useSelector(({ common }) => common.countries);

  const getSymbolForCurrency = (currencyCode) => {
    const country = countries.find((c) => c.currencyCode === currencyCode);
    return country ? country.curencySymbol : "";
  };

  const callQuotationList = useCallback(
    (page = 1, limit = 10) => {
      dispatch(fetchStart());
      MY_EXIM_API_RFQ.getQuotationList({ page, limit, enquiryId: id })
        .then((response) => {
          let dataItem = response?.data?.data;
          if (dataItem) {
            SetQuotationList(dataItem);
          }
          dispatch(fetchSuccess());
        })
        .catch((e) => {
          if (isValidString(e?.message)) {
            showAlert(e?.message);
          }
          dispatch(fetchSuccess());
        });
    },
    [dispatch, id]
  );

  useEffect(() => {
    callQuotationList();
  }, [callQuotationList]);

  const columns = useMemo(() => {
    return [
      {
        title: "Received Date",
        key: "createdAt",
        render: (record) => <div>{formatDate(record?.createdAt)}</div>,
      },
      {
        title: "Product Name",
        key: "productName",
        sorter: true,
        render: (record) => <div>{record?.enquiries?.productName}</div>,
      },
      {
        title: "Company Name",
        key: "user",
        render: (record) => (
          <div className="highlighted-column">
            {record?.sender?.companyName}
          </div>
        ),
      },
      ...(party !== BUSINESS_TYPE.CUSTOM_HOUSE_AGENT
        ? [
            {
              title: "Price",
              key: "price",
              sorter: true,
              render: (record) => <div>{getPricePerUnit(record)}</div>,
            },
          ]
        : []),
      {
        title: "Status",
        key: "country",
        sorter: true,
        render: (record) => (
          <div>
            <RFQStatusTags status={record?.status} />
          </div>
        ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (record) => (
          <Dropdown
            menu={{
              items: [
                {
                  label: "View Details",
                  key: "1",
                  icon: <EditOutlined />,
                  onClick: () => {
                    navigateToDetails(record?.id);
                  },
                },
              ],
            }}
          >
            <div
              className="myexim-action-icon"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined />
            </div>
          </Dropdown>
        ),
      },
    ];
  }, [navigateToDetails]);

  const renderContent = useCallback(
    (data) => {
      return isMobile ? (
        data.map((record) => {
          return (
            <ItemDetailMobile
              postedDate={formatDate(record.createdAt)}
              primaryRow={{
                text: record.enquiries?.productName,
                subText: getPricePerUnit(record),
              }}
              secondaryRow={{
                text: record.sender?.companyName,
              }}
              status={record?.status}
              StatusComponent={RFQStatusTags}
              handleNavigation={() => {
                navigateToDetails(record.id);
              }}
            />
          );
        })
      ) : (
        <Table
          rowClassName={(data, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          className="gx-table-responsive"
          columns={columns}
          dataSource={quotationList?.rows}
          pagination={{
            position: ["bottomRight"],
            pageSize: 10,
            showSizeChanger: true,
            onChange: callQuotationList,
          }}
          onRow={(record) => {
            return {
              onClick: (e) => {
                // Add specific conditions here for menu items
                if (
                  !e.target.classList.contains(
                    "ant-dropdown-menu-title-content"
                  )
                ) {
                  navigateToDetails(record.id);
                }
              },
            };
          }}
        />
      );
    },
    [
      callQuotationList,
      columns,
      isMobile,
      navigateToDetails,
      quotationList?.rows,
    ]
  );

  return (
    <Card
      title={
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Quotations
        </div>
      }
    >
      {isArrayNonEmpty(quotationList?.rows)
        ? renderContent(quotationList.rows)
        : null}
    </Card>
  );
};

export default QuotationsList;
