import React, { useEffect, useState } from "react";
import { Card, Button, List, Modal, Form, Input, Image } from "antd";
import { useHistory, useParams } from "react-router-dom";
import {
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import { MY_EXIM_API_RFQ } from "../../appRedux/services/requestQuotationsNetwork";
import { showAlert } from "../../util/helper";
import { BUSINESS_TYPE, RFQStatus } from "../../util/Constant";
import { DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { BASE_API_CONFIG } from "util/Api";
import Messages from "./../messages/index";
import RFQStatusTags from "./RFQStatus";
import { axiosInstance } from "../../util/Api";
import TextArea from "antd/lib/input/TextArea";
import { formatDate } from "util/helper";
import { getMessagesAction } from "../../appRedux/actions/requestQuotationAction";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";

const QuotationsDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("");

  const [quotationsData, setQuotationsData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageSubmit, setMessageSubmit] = useState(false);

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        setIsModalOpen(false);
        axiosInstance
          .post(`${BASE_API_CONFIG.baseURL}/messages`, {
            text: values.text,
            senderId: quotationsData.receiverId,
            sender: quotationsData?.receiver.name,
            receiverId: quotationsData.senderId,
            receiver: quotationsData?.sender.name,
            enquiryId: quotationsData.enquiryId,
            quotationId: quotationsData.id,
          })
          .then((response) => {
            setMessageSubmit(true);
            dispatch(
              getMessagesAction(
                true,
                quotationsData.id,
                quotationsData?.receiverId
              )
            );
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  useEffect(
    () => {
      if (isArrayNonEmpty(quotationsData?.attachments)) {
        setFileName(quotationsData?.attachments);
      }
      if (messageSubmit) {
        setMessageSubmit(false);
      }
    },
    [quotationsData],
    [messageSubmit]
  );

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: -100, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (isValidElement(id)) {
      dispatch(fetchStart());
      MY_EXIM_API_RFQ.getQuotationDetails({ id })
        .then((response) => {
          setQuotationsData(response?.data?.data);
          console.log(response);
          dispatch(fetchSuccess());
        })
        .catch((e) => {
          showAlert(e?.message);
          dispatch(fetchSuccess());
        });
    }
  }, []);
  const approveQuotation = () => {
    let params = {
      id: id,
      data: {
        ...quotationsData,
        status: RFQStatus.ORDER_PLACED,
      },
    };
    dispatch(fetchStart());
    MY_EXIM_API_RFQ.updateQuotationDetails(params)
      .then((response) => {
        if (isValidElement(response)) {
          history.goBack();
        }
        dispatch(fetchSuccess());
      })
      .catch((e) => {
        showAlert(e?.message);
        dispatch(fetchSuccess());
      });
  };

  const {
    sender,
    price,
    units,
    description,
    enquiries,
    createdAt,
    currencyCode,
    status,
  } = quotationsData ?? {};

  const { companyName, country } = sender ?? {};
  let data = [
    {
      label: "Received Date",
      value: formatDate(createdAt),
    },
    {
      label: "Company Name",
      value: (
        <div>
          {companyName} &nbsp;&nbsp;{" "}
          <span
            onClick={() => navigateBusinessCard(sender?.id)}
            className={"myexim-link"}
          >
            View Profile
          </span>
        </div>
      ),
    },
    isValidString(country) && {
      label: "Country",
      value: country,
    },
    isValidString(currencyCode) &&
      isValidString(price) &&
      isValidString(units) && {
        label: "Price / Unit",
        value: `${currencyCode} ${price} / ${units}`,
      },
    isValidString(enquiries?.qty) && {
      label: "Total Quantity",
      value: `${enquiries?.qty} ${units}`,
    },
    {
      label: "Response Message",
      value: description,
    },
    {
      label: "Status",
      value: <RFQStatusTags status={status} />,
    },
  ];
  const navigateBusinessCard = (id) => {
    if (id) {
      history.push(`/user-profile?id=${id}`);
    }
  };

  if (isValidElement(companyName)) {
    return (
      <>
        <Card className="myexim-detail-card">
          <div className="myexim-table-title">
            <LeftOutlined
              className={"back-icon"}
              size="large"
              onClick={() => {
                history.goBack();
              }}
            />
            Quotation Details
          </div>
          <div className="gx-pl-4">
            <List
              className="gx-mt-4"
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) =>
                item?.value && (
                  <List.Item className="myexim-details-list">
                    <List.Item.Meta
                      title={<div>{item.label}</div>}
                      description={item.value}
                    />
                  </List.Item>
                )
              }
            />
          </div>
          {/* {fileName?.length > 0 && (
            <div className="attachments gx-pl-2">
              <div className="sub-header"> Attachments</div>
              <div className="gx-pt-4">
                {fileName?.map((fileName, index) => (
                  <div key={index} className="gx-pb-3">
                    <Image
                      width={150}
                      height={150}
                      src={fileName?.src}
                      alt={fileName?.originalName}
                    />
                  </div>
                ))}
              </div>
            </div>
          )} */}
          {fileName?.length > 0 && (
            <div className="attachments gx-pl-2">
              <div className="sub-header">Attachments</div>
              <div className="gx-pt-4">
                {fileName?.map((file, index) => (
                  <div key={index} className="gx-pb-3">
                    <Image
                      width={150}
                      height={150}
                      src={file?.src}
                      alt={file?.originalName}
                    />
                    <div className="gx-pt-2">
                      <a
                        href={file?.downloadUrl || file?.src}
                        download={file?.originalName}
                        className="download-link"
                        style={{
                          color: "#1677ff",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DownloadOutlined style={{ fontSize: "18px" }} />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="gx-mt-3" style={{ display: "flex", justifyContent: isMobile ? "center" : "end", display: "flex", flex: 1, flexWrap: 'wrap' }}>
            <Button size="large" type="primary" onClick={showModal}>
              Send Message
            </Button>
            {enquiries?.status === RFQStatus.PUBLISHED && (
              <Button
                size="large"
                type="primary"
                onClick={() => approveQuotation()}
              >
                Place Order
              </Button>
            )}
          </div>
        </Card>
        <Messages
          id={quotationsData.id}
          loggedInUserId={quotationsData?.receiverId}
          isQuotation={true}
        />
        <Modal
          title="Send Message"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form} name="negotiateForm">
            <Form.Item
              name="text"
              rules={[{ required: true, message: "Please enter text!" }]}
            >
              <TextArea
                rows={10}
                placeholder="Add your notes"
                maxLength={250}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  } else return null;
};

export default QuotationsDetails;
