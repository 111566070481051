import React, { useCallback, useEffect, useMemo } from "react";
import { Card, Table, Button } from "antd";
import { Dropdown } from "antd";
import { DeleteOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryListAction,
  getHSCodeAction,
  getProductsAction,
  deleteProductAction,
} from "../../appRedux/actions/myProductsAction";
import { useHistory } from "react-router-dom";
import {
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import EnquiryStatusTags from "../../admin/enquiries/EnquiriesStatus";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { ItemDetailMobile } from "../../components/ItemDetailMobile";

const MyProductsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();

  useEffect(() => {
    dispatch(getProductsAction());
    dispatch(getCategoryListAction());
    dispatch(getHSCodeAction());
  }, [dispatch]);

  const productList = useSelector((state) => state.myProducts.productsList);
  const { loading } = useSelector(({ common }) => common);

  const onShowSizeChange = useCallback((current, pageSize) => {
    console.log('@@@ on Show size change')
    dispatch(getProductsAction(current, pageSize));
  }, [dispatch]);
  
  const onProductClick = useCallback((item) => {
    dispatch(deleteProductAction(item?.id));
    dispatch(getProductsAction(1, 10));
  }, [dispatch]);

  const navigateToDetails = useCallback((id) => {
    if (isValidElement(id)) {
      history.push(`my-products/details?id=${id}`);
    } else {
      history?.push("my-products/edit");
    }
  }, [history]);

  const moreActionsRenderer = useCallback((record) => (
    <div className="myexim-action-icon" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        menu={{
          items: [
            {
              label: "View",
              key: "1",
              icon: <EyeOutlined />,
              onClick: () => {
                navigateToDetails(record?.id);
              },
            },
            {
              label: "Delete",
              key: "2",
              icon: <DeleteOutlined />,
              onClick: () => {
                onProductClick(record);
              },
            },
          ],
        }}
        trigger={["click"]}
      >
        <MoreOutlined />
      </Dropdown>
    </div>
  ), [navigateToDetails, onProductClick]);

  const columns = useMemo(() => [
    {
      title: "Product Name",
      key: "Product Name",
      dataIndex: "name",
      sorter: true,
      width: "25%",
      render: (text, record) => (
        <div className={"gx-flex-row gx-align-items-center"}>
          <div className={"gx-pr-2"}>
            <img
              alt="..."
              className={"gx-size-40 gx-rounded-base gx-border-2"}
              src={isValidString(record?.primaryImage) && record?.primaryImage}
            />
          </div>
          <div className={"highlighted-column"}>{text}</div>
        </div>
      ),
    },
    {
      title: "Category",
      key: "Product Name",
      sorter: true,
      width: "25%",
      render: (record) => <div>{record?.categories?.name}</div>,
    },
    {
      title: "HS Code",
      key: "hsCode",
      render: (record) => <div>{record?.hscode?.code}</div>,
    },
    {
      title: "Status",
      key: "Status",
      sorter: true,
      render: (record) => {
        return <EnquiryStatusTags status={record?.status} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: moreActionsRenderer,
    },
  ], [moreActionsRenderer]);

  const renderContent = useCallback((data, count) => {
    return isMobile ? (
      data.map((record) => {
        return (
          <ItemDetailMobile
            imgData={{
              name: record.images?.[0].originalName,
              src: record.primaryImage,
            }}
            primaryRow={{
              text: record.name,
            }}
            secondaryRow={{
              text: `HS Code: ${record.hscode.code}`,
            }}
            status={record.status}
            StatusComponent={EnquiryStatusTags}
            MoreActions={() => moreActionsRenderer(record)}
            handleNavigation={() => {
              navigateToDetails(record.id)
            }}
          />
        )
      })
    ) : (
      <Table
        rowClassName={(_, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        onRow={(record) => {
          return {
            onClick: () => {
              navigateToDetails(record?.id);
            },
          };
        }}
        className="gx-table-responsive my-product-table myexim-table"
        total={count}
        columns={columns}
        dataSource={data}
        pagination={{
          position: ["bottomRight"],
          showSizeChanger: true,
          pageSize: 10,
          total: count,
          onChange: onShowSizeChange,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        footer={() =>
          count > 0 ? (
            <div
              style={{
                padding: "10px 0",
                fontWeight: "bold",
                borderTop: "1px solid #d9d9d9",
              }}
            >
              Note: Your Products with "Pending Approval" status require
              review by our team and will be approved or rejected within 24
              hours.
            </div>
          ) : null
        }
      />
    )
  }, [columns, isMobile, navigateToDetails, onShowSizeChange])

  return (
    <Card
      className={isMobile ? 'mobile-view': ''}
      title={
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <div className="myexim-table-title" >My Products</div>
          {isArrayNonEmpty(productList?.rows) && (
            <div style={{ paddingLeft: 15 }}>
              <Button type="primary" onClick={() => navigateToDetails()}>
                + Create New Product
              </Button>
            </div>
          )}
        </div>
      }
    >
      <div>
        {isArrayNonEmpty(productList?.rows) ? renderContent(productList.rows, productList.count) : (
          !loading && (
            <ListEmptyComponent
              message="Create Your Products to get the Enquiries for your Listed Products."
              buttonText="+Create Your New Products"
              onButtonClick={() => navigateToDetails()}
            />
          )
        )}
      </div>
    </Card>
  );
};

export default MyProductsList;
