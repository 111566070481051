import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { Button, Card, Progress, Tag } from "antd";
import { isBuyer, isExporter } from "../../../util/helper";
import { CERT_KEY } from "../../../util/Constant";
import { getAttchementByKey } from "../../myProfile/MyProfileView";
import { isValidString } from "util/ApiHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGetBreakpoints } from "../../../hooks/useGetBreakpoints";

export const calculateProfilePercent = (authUser) => {
  let percent = 100;
  const pending = [];

  if (!authUser.address) {
    percent -= 5;
    pending.push("Address");
  }
  if (!authUser.companyLogo || !isValidString(authUser.companyLogo)) {
    percent -= 5;
    pending.push("Company Logo");
  }
  if (isExporter(authUser)) {
    if (!isValidString(getAttchementByKey(authUser, CERT_KEY.EXIM)?.src)) {
      percent -= 5;
      pending.push("EXIM Certificate");
    }
    if (!isValidString(getAttchementByKey(authUser, CERT_KEY.GST)?.src)) {
      percent -= 5;
      pending.push("GST Certificate");
    }
  }
  if (isBuyer(authUser)) {
    if (!isValidString(getAttchementByKey(authUser, CERT_KEY.COMPANY)?.src)) {
      percent -= 5;
      pending.push("Company Certificate");
    }
  }

  return { percent, pending };
};

const VerificationBanner = ({ showButton }) => {
  const {isMobile } = useGetBreakpoints();
  const authUser = useSelector(({ auth }) => auth.userData);
  const [profilePercent, setProfilePercent] = useState(0);
  const [pendingFields, setPendingFields] = useState([]);
  console.log(authUser);

  useEffect(() => {
    const { pending, percent } = calculateProfilePercent(authUser);
    setProfilePercent(percent);
    setPendingFields(pending);
  }, [authUser]);
  const history = useHistory();
  const navigateProfile = () => {
    history.push(`/my-profile`);
  };

  return (
    <div>
      <Card>
        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: isMobile ? "column" : "row", gap: "10px" }}>
          <div>
            <div style={{ fontSize: 48, fontWeight: "bold" }}>
              {profilePercent}%
            </div>
            <div className="gx-h1-sm gx-font-weight-semi-bold">
              PROFILE COMPLETED
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Progress percent={profilePercent} showInfo={false} />
            {profilePercent < 100 ? (
              <>
                <div className="myexim-table-title">
                  Complete KYC to get profile verified !!
                </div>
                <div className="gx-pt-2">
                  Why Verify?
                  <br />
                  KYC Update adds verified to Your Profile and adds credibility
                  and Trust when your profile is viewed by other parties.
                  {pendingFields.length > 0 && (
                    <div style={{ marginTop: 10 }}>
                      <span className="gx-font-weight-bold">
                        Pending Details: &nbsp;
                      </span>
                      {pendingFields.map((field, index) => (
                        <Tag color="red"> {field}</Tag>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="myexim-table-title">
                  Awesome !! Your KYC update is compeleted.
                </div>
                <div className="gx-pt-2">
                  Your verification will be completed within 24 hours.
                </div>
                <div className="gx-pt-2">
                  <Tag color="red"> {"Pending Admin Approval"}</Tag>
                </div>
              </>
            )}
          </div>
        </div>
        {showButton && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={navigateProfile}>
              Go to Profile Page
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

export default VerificationBanner;
